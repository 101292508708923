.sidebar {
  background: #fff;
  width: 100%;
  max-width: 280px;
  text-align: left;
  z-index: 10;
  
  @media only screen and (max-width: 896px) {
    position: absolute;
    //background: #212121;
    //color: #C8C8C8;
  }
  
  .items li {
    display: flex;
    align-items: center;
    
    &:hover {
      background: rgba(244, 245, 247, .8);
      border-radius: 13px;
      transition: background 0.4s;
      cursor: pointer;
    }
  }
}
