.react-tel-input .form-control {
  border-radius: 17px;
  height: 56px;
  width: 100%;
  min-width: 300px;
}
.react-tel-input .flag-dropdown{
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background-color: transparent;
}

.react-tel-input .flag-dropdown.open {
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
}

.react-tel-input .special-label {
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 10px;
  top: -7px;
  background: #fff;
  color: #737373;
}