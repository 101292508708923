body {
  background-color: #62DEC2;
  position: relative;
  height: 100%;
}

body, html {
  height: 100%;
}

.inputRounded .MuiOutlinedInput-root{
  border-radius: 20px;
}

.App {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.auth-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 708px;
}

.auth-content .content {
  max-width: 538px;
  margin: 0 auto;
}

.wrapper::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 55%;
  left: -1000px;
  transform: matrix(1, -0.07, 0.08, 1, 0, 0);
  background: #172B4D;
  width: 3000px;
  height: 800px;
}

@media only screen and (max-width: 370px) {
  .auth-content {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 538px;
  }

  .auth-content::before {
    height: 500px;
  }

  .App {
    overflow: auto;
    overflow-x: hidden;
  }
}

.textfield {
  width: 100%;
  border-radius: 20px !important;
}

.logo {
  width: 140.91px;
}
