.header {
  width: 100%;
  height: 85px;
  color: #fff;
}

.avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.logo {
  width: 110px;
}