.admin {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: auto;

  .admin-body {
    width: 100%;
    position: absolute;
    min-height: 91% !important; /* browser fill */
    height: auto;
    top: 85px;

    .content {
      background: #F4F5F7;
      text-align: initial;
      width: 100%;
    }
  }
}

.tour-banner {
  width: 72px;
  height: 72px;
  border-radius: 50%;
}
