.MuiDataGrid-window::-webkit-scrollbar {
  display: none;
}
.MuiDataGrid-window {
-ms-overflow-style: none;
scrollbar-width: none;
}

.MuiTableContainer-root::-webkit-scrollbar {
  display: none;
}
.MuiTableContainer-root {
  -ms-overflow-style: none;
  scrollbar-width: none;
  }
