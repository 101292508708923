.quill {

}
.ql-container {
  height: 83%
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  margin: 0px 10px;
  border: 1px solid #919EAB;
  border-radius: 12px;
  font-size: 14px;
  font-family: Poppins;
  text-align: justify !important;

}
.ql-toolbar.ql-snow{
  padding: 0px 0px 10px 0px;
  border: none;
}

.quill > .ql-container > .ql-editor.ql-blank::before{
  font-size: 14px;
  font-family: Poppins;
  text-align: justify !important;
}
