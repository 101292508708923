.fc .fc-button-primary,
.fc .fc-button-primary:hover,
.fc .fc-button-primary:visited,
.fc .fc-button-primary:active,
.fc .fc-button-primary:focus {
  color: #172B4D !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.fc-header-days {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #8080806e;
  font-size: 14px;
  background-color: #fafafa;
}
.fc-toolbar {
  text-transform: capitalize;
}
.text-capitalize {
  text-transform: lowercase;
}
.text-capitalize::first-letter {
  text-transform: uppercase;
}
.fc-day-today {
  background: #fff !important;
}