.text-breadcrumb {
  color: #6B778C;
}
.text-breadcrumb.active {
  cursor: pointer;
  color: #172B4D;
}

@media only screen and (max-width: 896px) {
  .font-breadcrumb{
    font-size: 14px !important;
  }
}
